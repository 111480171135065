.el-date-editor.el-input[data-v-36488746], .el-date-editor.el-input__inner[data-v-36488746] {
  width: 180px !important;
}
.save_box[data-v-36488746] {
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center[data-v-36488746] {
  text-align: center;
  color: #333 !important;
}
.weight[data-v-36488746] {
  font-size: 13px !important;
  font-weight: 600;
}
.left[data-v-36488746] {
  text-align: left;
}
.right[data-v-36488746] {
  text-align: right;
}
.left_k[data-v-36488746] {
  text-indent: 41px;
}
.left_k[data-v-36488746] {
  text-indent: 2em;
}
.left_indent[data-v-36488746] {
  text-indent: 4em;
}
.left_big_indent[data-v-36488746] {
  text-indent: 8em;
}
.border_none .el-select[data-v-36488746] {
  width: 100% !important;
}
.border_none .el-input__inner[data-v-36488746] {
  width: 100% !important;
  background-color: none !important;
}
.input_width input[data-v-36488746] {
  width: 60px !important;
  margin: 0 5px;
}
.gary_box[data-v-36488746] {
  background: #f8f8f8;
  text-align: right;
}
.blue_box[data-v-36488746] {
  background: #fff;
}
.el-radio + .el-radio[data-v-36488746] {
  margin-left: 7px;
}
.el-radio__label[data-v-36488746] {
  font-size: 13px !important;
}
.el-date-editor.el-input[data-v-36488746], .el-date-editor.el-input__inner[data-v-36488746] {
  width: 180px !important;
}
.app-container[data-v-36488746] {
  position: relative;
}
.app-container .right_btns[data-v-36488746] {
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 99;
}
.content[data-v-36488746] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-36488746] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-36488746] {
  padding: 0;
  margin: 0;
}
.content td[data-v-36488746] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.content td p[data-v-36488746] {
  display: inline-block;
  color: #409EFF;
  cursor: pointer;
}
.content input[data-v-36488746] {
  width: 100%;
  font-size: 13px;
  text-align: right;
  padding-right: 12px;
}
.content2[data-v-36488746] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content2 span[data-v-36488746] {
  line-height: 26px;
  font-size: 13px;
}
.content2 tr[data-v-36488746] {
  padding: 0;
  margin: 0;
}
.content2 td[data-v-36488746] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 26px;
  padding: 0 6px;
  color: #666;
  font-size: 13px;
}
.content2 td p[data-v-36488746] {
  display: inline-block;
  color: #409EFF;
  cursor: pointer;
}
.content2 input[data-v-36488746] {
  width: 100%;
  line-height: 26px;
  font-size: 13px;
  text-align: right;
  padding-right: 20px;
}
.big_box4[data-v-36488746] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}